//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ownEquipmentImg from '@/shared/assets/ownEquipment.svg';
import quickImg from '@/shared/assets/quick.svg';
import noHiddenFeesImg from '@/shared/assets/noHiddenFees.svg';

export default {
  name: 'Financing_Features',
  data: () => ({
    blocks: [
      {
        img_url: quickImg,
        alt: 'A clock with hands',
        title: 'Quick and easy',
        text: "Enter a few pieces of information for a real-time decision. Checking your eligibility won't affect your credit score.",
      },
      {
        img_url: noHiddenFeesImg,
        alt: 'A magnifying glass',
        title: 'No hidden fees',
        text: 'Know exactly what you\'ll owe before you commit. There are no hidden costs and no surprises.',
      },
      {
        img_url: ownEquipmentImg,
        alt: 'The FightCamp free-standing bag',
        title: 'Yours to keep',
        text: 'Own your FightCamp equipment right away. Simply pay over time.',
      },
    ],
  }),
};
